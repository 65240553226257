import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Destroyable } from '@fund-base/components/destroyable/destroyable';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

interface Tip {
  content: string;
  page: string;
}

interface TipsResponse {
  tips: Tip[];
}

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Loader extends Destroyable implements OnInit {
  tips: Tip[] = [];
  currentTip: string = '';

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  ngOnInit() {
    //this.loadTips().then(() => {
    //  this.router.events
    //    .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
    //    .subscribe((ev: NavigationEnd) => {
    //      this.loadTips().then(() => {
    //        this.currentTip = this.getRandomTip(ev.urlAfterRedirects);
    //        //console.log(this.currentTip);
    //      });
    //    });
    //});
  }

  loadTips() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<TipsResponse>('assets/loader/loader-tips.json').subscribe(
        response => {
          this.tips = response.tips;
          resolve();
        },
        error => {
          console.error('Error loading tips', error);
          reject(error);
        }
      );
    });
  }

  getRandomTip(currentUrl: string): string {
    //console.log(currentUrl);
    //const validTips = this.tips.filter(tip => tip.page === 'all' || tip.page === currentUrl);
    const validTips = this.tips;
    //console.log(validTips);
    if (validTips.length > 0) {
      const randomIndex = Math.floor(Math.random() * validTips.length);
      return validTips[randomIndex].content;
    } else {
      return 'No tip found';
    }
  }
}
