import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { TranslocoService } from '@ngneat/transloco';
import {
  addOrganizationComment,
  deleteInvoice,
  fetchOrganizationSettings,
  removeOrganizationComment,
  setOrganization,
  setOrganizationCommentsList,
  setOrganizationLoading,
  setOrganizationSettings,
  updateOrganizationOnboardingSkipped,
  updateOrganizationSettings,
} from '@fund-base/store/actions/organization.actions';
import { OrganizationService } from '@fund-base/services/organization/organization.service';
import { selectLanguage } from '@fund-base/store/selectors/locale.selectors';
import { setToken, setUser } from '@fund-base/store/actions/auth.actions';
import { UserService } from '@fund-base/services/user/user.service';

@Injectable()
export class OrganizationEffects {
  // update organization settings
  fetchOrganizationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchOrganizationSettings),
      withLatestFrom(this.store.select(selectLanguage)),
      switchMap(([action, lang]) => {
        // set auth loading
        this.store.dispatch(setOrganizationLoading({ loading: true }));
        return this.organizationService.getOrganizationSettings(lang).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setOrganizationLoading({ loading: false }),
              setOrganizationSettings({ organizationSettings: response?.organizationSettings }),
              // showToast({
              //   content: this.translocoService.translate('Organization settings saved Successfully'),
              //   status: ToastType.success
              // })
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setOrganizationLoading({ loading: false }),
              showToast({ content: error, status: ToastType.failure }),
            ];
          })
        );
      })
    )
  );

  updateOrganizationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationSettings),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setOrganizationLoading({ loading: true }));
        return this.organizationService.updateOrganizationSettings(action?.organizationSettings).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setOrganizationLoading({ loading: false }),
              setOrganizationSettings({ organizationSettings: response?.organizationSettings }),
              setUser({ user: response?.user?.user }),
              setToken({ token: response?.user?.accessToken }),
              // showToast({
              //   content: this.translocoService.translate('Organization settings saved Successfully'),
              //   status: ToastType.success
              // })
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setOrganizationLoading({ loading: false }),
              showToast({ content: error, status: ToastType.failure }),
            ];
          })
        );
      })
    )
  );

  onboardingSkipped$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationOnboardingSkipped),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setOrganizationLoading({ loading: true }));
        return this.organizationService.updateOnboardingCompleted(action?.completed).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setOrganizationLoading({ loading: false }),
              setOrganization({ organization: response?.organization }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setOrganizationLoading({ loading: false }),
              showToast({ content: error, status: ToastType.failure }),
            ];
          })
        );
      })
    )
  );

  removeACommentFromList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOrganizationComment),
      switchMap(action => {
        return this.userService.removeACommentFromList(action?.commentId).pipe(
          switchMap(response => {
            if (!!action?.onSuccess) action?.onSuccess(response?.organizationComments);
            return [
              setOrganizationCommentsList({ organizationComments: response?.organizationComments }),
              showToast({
                content: this.translocoService.translate('Comment has been removed Successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            const error = this.errorService.getErrorMessage(errorRes);

            if (!!action?.onError) action?.onError?.call(error);

            return [showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  addACommentFromList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrganizationComment),
      switchMap(action => {
        return this.userService.addACommentToList(action?.fundId, action?.comment).pipe(
          switchMap(response => {
            if (!!action?.onSuccess) action?.onSuccess(response);
            return [
              setOrganizationCommentsList({ organizationComments: response?.organizationComments }),
              showToast({
                content: this.translocoService.translate('Comment has been saved Successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            const error = this.errorService.getErrorMessage(errorRes);

            if (!!action?.onError) action?.onError?.call(error);

            return [showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  deleteInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteInvoice),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setOrganizationLoading({ loading: false }));

        // call  from service
        return this.organizationService.deleteInvoice(action?.invoiceId).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setOrganizationLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Invoice deleted successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setOrganizationLoading({ loading: false }),
              showToast({ content: error, status: ToastType.failure }),
            ];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    private translocoService: TranslocoService,
    private organizationService: OrganizationService,
    private userService: UserService
  ) {}
}
