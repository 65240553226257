import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateSmartAgentDto, SmartAgent, UpdateSmartAgentDto } from '@fund-base/types/smart-agent/smart-agent.types';
import { smartAgentBaseApiUrl, smartAgentMailBaseApiUrl } from '@fund-base/constants/smart-agent/smart-agent.constants';
import { SmartAgentParams } from '@fund-base/shared/interfaces/filter.interfaces';
import { generateParams } from '@fund-base/utils/pagination/paginations-utils';

@Injectable({ providedIn: 'root' })
export class SmartAgentService {
  constructor(private httpClient: HttpClient) {}

  createSmartAgent(SmartAgent: CreateSmartAgentDto) {
    return this.httpClient.post<boolean>(smartAgentBaseApiUrl, SmartAgent);
  }

  getSmartAgentByQuery(params: SmartAgentParams) {
    return this.httpClient.get<SmartAgent[]>(smartAgentBaseApiUrl, {
      params: generateParams(params),
    });
  }

  updateSmartAgent(smartAgent: UpdateSmartAgentDto) {
    return this.httpClient.put<boolean>(smartAgentBaseApiUrl + '/' + smartAgent?.id, smartAgent);
  }

  deleteSmartAgent(smartAgentId: number) {
    return this.httpClient.delete<boolean>(smartAgentBaseApiUrl + '/' + smartAgentId);
  }

  deleteSmartAgentMail(smartAgentMailId: number) {
    return this.httpClient.delete<boolean>(smartAgentMailBaseApiUrl + '/' + smartAgentMailId);
  }
}
