import { Injectable } from '@angular/core';
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { first, flatMap } from 'rxjs/operators';
import { selectLanguage } from '@fund-base/store/selectors/locale.selectors';
import { Language } from '@fund-base/types/locale/locale.types';
import { baseApiUrl } from '@fund-base/constants/general/general.constants';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return this.store.select(selectLanguage).pipe(
      first(),
      flatMap(language => {
        if (!language) {
          language = Language.English;
        }
        if (req.url.includes(baseApiUrl)) {
          req = req.clone({ headers: req.headers.set('language', language) });
        }
        return next.handle(req);
      })
    );
  }
}
