import {
  CreateSubmissionDto,
  CreateTaskDto,
  CreateWorkPlanDto,
  UpdateSubmissionDto,
  UpdateTaskDto,
  UpdateWorkPlanDto,
  WorkPlan,
  WorkPlanTask,
} from '@fund-base/types/workplan/workplan.types';
import { HttpClient } from '@angular/common/http';
import { workplanBaseApiUrl } from '@fund-base/constants/work-plan/work-plan.constants';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Params } from '@fund-base/shared/interfaces/filter.interfaces';
import { generateParams } from '@fund-base/utils/pagination/paginations-utils';

@Injectable({ providedIn: 'root' })
export class WorkPlanService {
  constructor(private httpClient: HttpClient) {}

  createWorkplan(workPlanDto: CreateWorkPlanDto) {
    return this.httpClient.post<boolean>(workplanBaseApiUrl, {
      ...workPlanDto,
    });
  }

  updateWorkplan(updateDto: UpdateWorkPlanDto) {
    return this.httpClient.put<boolean>(workplanBaseApiUrl, {
      ...updateDto,
    });
  }

  createTask(createTaskDto: CreateTaskDto): Observable<WorkPlanTask> {
    return this.httpClient.post<WorkPlanTask>(workplanBaseApiUrl + '/task', {
      ...createTaskDto,
    });
  }

  updateTask(updateDto: UpdateTaskDto) {
    return this.httpClient.put<boolean>(workplanBaseApiUrl + '/task', {
      ...updateDto,
    });
  }

  deleteTask(taskId: number) {
    return this.httpClient.delete<boolean>(workplanBaseApiUrl + '/task/' + taskId);
  }

  createSubmission(submissionDto: CreateSubmissionDto) {
    return this.httpClient.post<boolean>(workplanBaseApiUrl + '/submission', {
      ...submissionDto,
    });
  }

  updateSubmission(updateSubmissionDto: UpdateSubmissionDto) {
    return this.httpClient.put<boolean>(workplanBaseApiUrl + '/submission', {
      ...updateSubmissionDto,
    });
  }

  getWorkplansByQuery(params: Params) {
    return this.httpClient.post<WorkPlan[] | undefined>(`${workplanBaseApiUrl}/list`, generateParams(params));
  }

  searchWorkplans(textQuery: string) {
    if (!textQuery) {
      of([]);
    }
    return this.httpClient.get<WorkPlan[]>(workplanBaseApiUrl + '/filter/' + textQuery);
  }

  updateSubmissionFiles(filesUrls: string[], submissionId: number | undefined) {
    return this.httpClient.put(workplanBaseApiUrl + '/submission/upload-files/' + submissionId, filesUrls);
  }
}
