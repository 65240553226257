import { PhilanthropicContent, Tags } from '@fund-base/types/philanthropic-content/philanthropic-content.types';

export interface PhilanthropicContentState {
  contentList: PhilanthropicContent[];
  selectedTags: Tags[];
  loading: boolean;
  error: string | null;
  page: number;
  pageSize: number;
  hasMore: boolean;
}

export const initialPhilanthropicContentState: PhilanthropicContentState = {
  contentList: [],
  selectedTags: [],
  loading: false,
  error: null,
  page: 0,
  pageSize: 10,
  hasMore: true,
};
