import { createReducer, on } from '@ngrx/store';
import * as ContentActions from '../actions/philanthropic-content.actions';
import { PhilanthropicContentState, initialPhilanthropicContentState } from '../state/philanthropic-content.state';

export const featurePhilanthropicContentKey = 'philanthropicContent';

export const philanthropicContentReducer = createReducer(
  initialPhilanthropicContentState,
  on(ContentActions.loadContent, (state, { tags, page, pageSize }) => ({
    ...state,
    loading: true,
    selectedTags: tags,
    page,
    pageSize,
  })),
  on(ContentActions.loadContentSuccess, (state, { content, page, hasMore, isReplace }) => {
    if (isReplace) {
      return {
        ...state,
        loading: false,
        contentList: [...content],
        hasMore,
      };
    }
    return {
      ...state,
      loading: false,
      contentList: page === 0 ? content : [...state.contentList, ...content],
      hasMore,
    };
  }),
  on(ContentActions.loadContentFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
