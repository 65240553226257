import { Organization, OrganizationSettings } from '@fund-base/types/organization/organization.types';
import { User } from '@fund-base/types/auth/auth.types';
import { OrganizationUsersCommentsFunds } from '@fund-base/types/users/organization-users-comments.types';

export interface OrganizationState {
  organization?: Organization;
  organizationSettings?: OrganizationSettings;
  onboardingLoading: boolean;
  onboardingSkipped: boolean;
  organizationUsersList?: User[];
  organizationComments?: OrganizationUsersCommentsFunds[];
}

export const initialOrganizationState: OrganizationState = {
  organization: undefined,
  organizationSettings: undefined,
  onboardingLoading: false,
  onboardingSkipped: false,
  organizationUsersList: [],
  organizationComments: [],
};
