import { User, Visitor } from '@fund-base/types/auth/auth.types';

export interface AuthState {
  user?: User;
  token?: string;
  loading: boolean;
  visitor?: Visitor;
}

export const initialAuthState: AuthState = {
  token: undefined,
  user: undefined,
  loading: false,
  visitor: undefined,
};
