import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FundType, Interest } from '@fund-base/types/types/types.types';
import { adminFundTypesApiUrl, adminInterestsApiUrl } from '@fund-base/constants/admin/admin.constants';
import { Language } from '@fund-base/types/locale/locale.types';

@Injectable({ providedIn: 'root' })
export class TypesService {
  constructor(private httpClient: HttpClient) {}

  // get funds types
  getFundTypes(lang?: Language): Observable<{ fundTypes: FundType[] }> {
    return this.httpClient.get<{ fundTypes: FundType[] }>(`${adminFundTypesApiUrl}/${lang}`);
  }

  getInterests(lang: Language): Observable<{ interests: Interest[] }> {
    return this.httpClient.get<{ interests: Interest[] }>(`${adminInterestsApiUrl}/${lang}`);
  }
}
