import { FundType, Interest } from '@fund-base/types/types/types.types';

export interface TypesState {
  loading: boolean;
  fundTypes: FundType[];
  interests: Interest[];
}

export const initialTypesState: TypesState = {
  loading: false,
  fundTypes: [],
  interests: [],
};
