import { CreditCardType, CreditCardTypeEnum } from '@fund-base/types/payment/payment.types';
import { baseApiUrl } from '@fund-base/constants/general/general.constants';

export const creditCardTypesList: CreditCardType[] = [
  {
    type: CreditCardTypeEnum.mastercard,
    title: 'Mastercard',
    logo: 'assets/images/mastercard-icon.svg',
  },
  {
    type: CreditCardTypeEnum.visa,
    title: 'Visa',
    logo: 'assets/images/visa-icon.svg',
  },
  {
    type: CreditCardTypeEnum.americanExpress,
    title: 'American Express',
    logo: 'assets/images/american-express-icon.svg',
  },
];

export const paymentsBaseApiUrl = `${baseApiUrl}/payments`;
export const subscriptionBaseApiUrl = `${paymentsBaseApiUrl}/subscription`;
export const membershipBaseApiUrl = `${paymentsBaseApiUrl}/membership`;
export const expertBaseApiUrl = `${paymentsBaseApiUrl}/expert`;
export const invoicesBaseApiUrl = `${paymentsBaseApiUrl}/invoices`;
