import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, initialAuthState } from '@fund-base/store/state/auth.state';
import {
  authenticate,
  authWithGoogle,
  getGoogleAuthInfo,
  login,
  setAuthLoading,
  setToken,
  setUser,
  setVisitor,
  signOut,
  signUp,
  signUpEmailVerification,
  signUpMembership,
  signUpOrganization,
  signUpReSendEmailVerification,
  signUpVisitor,
} from '@fund-base/store/actions/auth.actions';

export const featureAuthKey = 'auth';

const _authReducer = createReducer<AuthState, Action>(
  initialAuthState,
  on(
    authenticate,
    login,
    signUp,
    signUpVisitor,
    signUpOrganization,
    signUpEmailVerification,
    signUpReSendEmailVerification,
    signUpMembership,
    signOut,
    authWithGoogle,
    getGoogleAuthInfo,
    state => state
  ),
  on(setAuthLoading, (state, { loading }) => ({ ...state, loading })),
  on(setUser, (state, { user }) => ({ ...state, user })),
  on(setVisitor, (state, { visitor }) => ({ ...state, visitor })),
  on(setToken, (state, { token }) => ({ ...state, token }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}
