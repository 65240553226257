import { OrganizationState } from '@fund-base/store/state/organization.state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { featureOrganizationKey } from '@fund-base/store/reducers/organization.reducer';
import { Organization, OrganizationSettings } from '@fund-base/types/organization/organization.types';
import { User } from '@fund-base/types/auth/auth.types';
import { OrganizationUsersCommentsFunds } from '@fund-base/types/users/organization-users-comments.types';

// get organization
const getOrganization = (state: OrganizationState): Organization | undefined => state?.organization;

// get organization settings
const getOrganizationSettings = (state: OrganizationState): OrganizationSettings | undefined =>
  state?.organizationSettings;

// get on boarding loading
const getOnboardingLoading = (state: OrganizationState): boolean => state.onboardingLoading;
// get organization on boarding skipped
const getOrganizationOnboardingSkipped = (state: OrganizationState): boolean | undefined =>
  state.organization?.on_boarding_completed;

const getOrganizationUsersList = (state: OrganizationState): User[] | undefined => state.organizationUsersList;
const getOrganizationCommentsList = (state: OrganizationState): OrganizationUsersCommentsFunds[] | undefined =>
  state.organizationComments;

export const selectOrganizationState: MemoizedSelector<object, OrganizationState> =
  createFeatureSelector<OrganizationState>(featureOrganizationKey);

// select organization settings
export const selectOrganization: MemoizedSelector<object, Organization | undefined> = createSelector(
  selectOrganizationState,
  getOrganization
);

export const selectOrganizationUsersList: MemoizedSelector<object, User[] | undefined> = createSelector(
  selectOrganizationState,
  getOrganizationUsersList
);

export const selectOrganizationCommentsList: MemoizedSelector<object, OrganizationUsersCommentsFunds[] | undefined> =
  createSelector(selectOrganizationState, getOrganizationCommentsList);

// select organization settings
export const selectOrganizationSettings: MemoizedSelector<object, OrganizationSettings | undefined> = createSelector(
  selectOrganizationState,
  getOrganizationSettings
);

// select organization on boarding skipped
export const selectOrganizationOnboardingSkipped: MemoizedSelector<object, boolean | undefined> = createSelector(
  selectOrganizationState,
  getOrganizationOnboardingSkipped
);

// select organization on boarding loading
export const selectOnboardingLoading: MemoizedSelector<object, boolean> = createSelector(
  selectOrganizationState,
  getOnboardingLoading
);
