import { baseApiUrl } from '@fund-base/constants/general/general.constants';
import { InjectionToken } from '@angular/core';

export const adminBaseApiUrl = `${baseApiUrl}/system`;
export const adminInterestsApiUrl = `${adminBaseApiUrl}/categories`;
export const adminFundTypesApiUrl = `${adminBaseApiUrl}/types`;
export const adminFundLanguagesApiUrl = `${adminBaseApiUrl}/languages`;
export const adminFundCountriesApiUrl = `${adminBaseApiUrl}/countries`;

// funds service injection token
export const adminServiceInjectionToken = new InjectionToken('admin.service');
