import { InjectionToken } from '@angular/core';
import { baseApiUrl } from '@fund-base/constants/general/general.constants';

// auth api
export const authBaseApiUrl = `${baseApiUrl}/auth`;
export const authLoginApiUrl = `${authBaseApiUrl}/login`;
export const authForgotPasswordApiUrl = `${authBaseApiUrl}/forgot-password`;
export const authResetPasswordApiUrl = `${authBaseApiUrl}/reset-password`;
export const authSignupApiUrl = `${authBaseApiUrl}/signup/user`;
export const authSignupVisitorApiUrl = `${authBaseApiUrl}/signup/user-visitor`;
export const authSignupVisitorPlanApiUrl = `${authBaseApiUrl}/signup/visitor-plan`;
export const authSignupVisitorUpdateApiUrl = `${authBaseApiUrl}/signup/visitor-update`;
export const authSignupOldFbAccountApiUrl = `${authBaseApiUrl}/signup/old-fb-account`;
export const authSignupOrganizationApiUrl = `${authBaseApiUrl}/signup/organization`;
export const authEmailVerificationApiUrl = `${authBaseApiUrl}/signup/email-verification`;
export const authVisitorEmailVerificationApiUrl = `${authBaseApiUrl}/signup/visitor-email-verification`;
export const authReSendEmailVerificationApiUrl = `${authBaseApiUrl}/signup/resend-email-verification`;
export const authIsEmailVerifiedUrl = `${authBaseApiUrl}/signup/isVerified`;
export const authTransformVisitorToUserUrl = `${authBaseApiUrl}/signup/visitor-to-user`;
export const authIsVisitorEmailVerifiedUrl = `${authBaseApiUrl}/visitor/isVerified`;
export const authSignupMembershipApiUrl = `${authBaseApiUrl}/signup/membership`;
export const authSignupSuccessApiUrl = `${authBaseApiUrl}/signup/success`;
export const authAuthenticateApiUrl = `${authBaseApiUrl}/authenticate`;
export const authAuthenticateVisitorApiUrl = `${authBaseApiUrl}/authenticate-visitor`;
export const authVisitorFreePlanApiUrl = `${authBaseApiUrl}/visitor/free-plan`;
export const authVisitorAiIncreaseLettersApiUrl = `${authBaseApiUrl}/visitor-ai`;
export const authVisitorStatusApiUrl = `${authBaseApiUrl}/signup/visitor-status`;

// auth service injection token
export const authServiceInjectionToken = new InjectionToken('auth.service');

// auth local storage keys
export const authUserLocalStorageKey = 'user';
export const authTokenLocalStorageKey = 'token';
