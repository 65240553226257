import { createAction, props } from '@ngrx/store';
import { UpdateUser } from '@fund-base/types/users/update-user.types';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { ResetPasswordDto } from '@fund-base/types/auth/auth.types';
import { UserRolePair } from '@fund-base/types/organization/organization.types';
import { UserFavourites } from '@fund-base/types/users/user-favourites.types';
import { AddNewUserTypes } from '@fund-base/types/users/add-new-user.types';

// user loading
export const setUserLoading = createAction('[User] Set settings loading', props<{ loading: boolean }>());

// update profile
export const updateProfileSettings = createAction(
  '[User] Set users',
  props<{ userUpdate: UpdateUser; profileFile?: File | null; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// change password request
export const resetPassword = createAction(
  '[User] reset password request',
  props<{ resetPasswordDto: ResetPasswordDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// update users roles
export const updateUsersRoles = createAction(
  '[User] Update users roles',
  props<{ usersRoles?: UserRolePair[]; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set users favourites funds list
export const fetchUsersFavouritesFundsList = createAction(
  '[User] Fetch Favourite Funds List',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set users favourites funds list
export const setUsersFavouritesFundsList = createAction(
  '[User] Set Favourite Funds List',
  props<{ userFavouritesList?: UserFavourites[]; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// update users favourites funds list
export const updateUsersFavouritesFundsList = createAction(
  '[User] update Favourite Funds List',
  props<{ fundId?: number; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
export const addNewUserToOrganization = createAction(
  '[User] Add New User To Organization',
  props<{ language: string; newUserDetails: AddNewUserTypes; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
