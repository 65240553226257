import { createAction, props } from '@ngrx/store';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { SmartAgentParams } from '@fund-base/shared/interfaces/filter.interfaces';
import { CreateSmartAgentDto, SmartAgent, UpdateSmartAgentDto } from '@fund-base/types/smart-agent/smart-agent.types';

export const setSmartAgent = createAction('[SmartAgent] Set SmartAgent', props<{ smartAgent?: SmartAgent }>());

export const setSmartAgentLoading = createAction('[SmartAgent] Set SmartAgent loading', props<{ loading: boolean }>());

export const fetchSmartAgents = createAction(
  '[SmartAgent] Fetch SmartAgents',
  props<{ params: SmartAgentParams; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const setSmartAgents = createAction('[SmartAgent] Set SmartAgents', props<{ smartAgents?: SmartAgent[] }>());

export const createSmartAgent = createAction(
  '[SmartAgent] Create SmartAgent',
  props<{ SmartAgent: CreateSmartAgentDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const updateSmartAgent = createAction(
  '[SmartAgent] Update SmartAgent',
  props<{ SmartAgent: UpdateSmartAgentDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const deleteSmartAgent = createAction(
  '[SmartAgent] Delete SmartAgent',
  props<{ smartAgentID: number; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const deleteSmartAgentMail = createAction(
  '[SmartAgent] Delete SmartAgentMail',
  props<{ smartAgentMailId: number; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
