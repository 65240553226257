import { Fund, FundsOpenSearch } from '@fund-base/types/funds/funds.types';
import { FiltersSearch, FundsFilter, SearchHistory, SearchQuery } from '@fund-base/types/funds/funds-search.types';
import { FundingExpertsList } from '@fund-base/types/funding-expert/expert.types';

export interface FundsState {
  id?: string;
  loading: boolean;
  filtersCountLoading: boolean;
  fund?: Fund;
  fundsList?: FundsOpenSearch[];
  fundsCount?: number;
  filtersCount?: FundsFilter[];
  filtersSearch?: FiltersSearch;
  lastFilter?: FiltersSearch;
  searchQuery?: SearchQuery;
  fundingExperts?: FundingExpertsList;
  usersSearchHistory?: SearchHistory[];
  page?: number;
  size?: number;
}

export const initialFundsState: FundsState = {
  id: undefined,
  fund: undefined,
  loading: false,
  filtersCountLoading: false,
  fundsList: [],
  fundsCount: 0,
  filtersSearch: {
    type: [],
    areaOfInterests: [],
    areaOfActivity: [],
    locationOfTheFund: null,
    fundingRange: {
      min: '0',
      max: '0',
    },
    textSearch: undefined,
    email: false,
    webSite: false,
    phone: false,
    newestFunds: false,
    limitations: false,
    delegations: false,
    monthsToApply: [],
  },
  searchQuery: undefined,
  usersSearchHistory: [],
  fundingExperts: undefined,
  page: 0,
  size: 10,
};
