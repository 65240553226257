import { InjectionToken } from '@angular/core';
import { baseApiUrl } from '@fund-base/constants/general/general.constants';

// plan api
export const planBaseApiUrl = `${baseApiUrl}/plans`;
export const planPlanChoosingApiUrl = `${baseApiUrl}`;

// plan service injection token
export const planServiceInjectionToken = new InjectionToken('plan.service');

// plan local storage keys
export const planLocalStorageKey = 'plan';
