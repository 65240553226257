import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs';
import { setAuthLoading, setToken, setUser } from '@fund-base/store/actions/auth.actions';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { TranslocoService } from '@ngneat/transloco';
import {
  addNewUserToOrganization,
  fetchUsersFavouritesFundsList,
  resetPassword,
  setUserLoading,
  setUsersFavouritesFundsList,
  updateProfileSettings,
  updateUsersFavouritesFundsList,
  updateUsersRoles,
} from '@fund-base/store/actions/users.action';
import { UserService } from '@fund-base/services/user/user.service';
import { setOrganizationLoading, setUsersList } from '@fund-base/store/actions/organization.actions';
import { selectUsersFavouritesFunds } from '@fund-base/store/selectors/users.selector';

@Injectable()
export class UsersEffects {
  // update profile settings
  updateProfileSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProfileSettings),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setUserLoading({ loading: true }));
        return this.userService.updateProfileSettings(action?.userUpdate, action?.profileFile).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setUserLoading({ loading: false }),
              setUser({ user: response.user }),
              setToken({ token: response?.accessToken }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setUserLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // reset password
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPassword),
      switchMap(action => {
        // get token

        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.userService.resetPassword(action?.resetPasswordDto).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [setAuthLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  updateUsersRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUsersRoles),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.userService.updateUsersRoles(action?.usersRoles).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setOrganizationLoading({ loading: false }),
              setUsersList({ organizationUsersList: response?.usersList ?? [] }),
              showToast({
                content: this.translocoService.translate('Users roles updated successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  fetchUserFavouriteFundsList = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUsersFavouritesFundsList),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setUserLoading({ loading: true }));
        return this.userService.getUsersFavouritesFundsList().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            return [
              setUserLoading({ loading: false }),
              setUsersFavouritesFundsList({ userFavouritesList: response?.userFavouritesList ?? [] }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  updateUsersFavouriteFundsList = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUsersFavouritesFundsList),
      withLatestFrom(this.store.select(selectUsersFavouritesFunds)),
      switchMap(([action, favouritesFundsList]) => {
        // set auth loading
        this.store.dispatch(setUserLoading({ loading: true }));
        const favouritesFundsListLength =
          favouritesFundsList && favouritesFundsList?.length > 0 ? favouritesFundsList?.length : 0;
        return this.userService.updateUsersFavouritesFundsList(action?.fundId).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setUserLoading({ loading: false }),
              setUsersFavouritesFundsList({ userFavouritesList: response?.userFavouritesList ?? [] }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setAuthLoading({ loading: false }),
              // showToast({content: error, status: ToastType.failure})
            ];
          })
        );
      })
    )
  );
  addNewUserToOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewUserToOrganization),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setUserLoading({ loading: true }));
        return this.userService.addNewUser(action.language, action.newUserDetails).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setUserLoading({ loading: false }),
              setUsersList({ organizationUsersList: response?.data ?? [] }),
              showToast({
                content: this.translocoService.translate('User Has Been Added To Organization'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    private translocoService: TranslocoService,
    private userService: UserService
  ) {}
}
