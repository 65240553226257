import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { NotificationsService } from '@fund-base/services/notifications/notifications.service';
import { setNotifications, setNotificationsRead } from '@fund-base/store/actions/notifications.actions';

@Injectable()
export class NotificationsEffects {
  notificationsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNotificationsRead),
      switchMap(action => {
        return this.notificationsService.readNotifications(action?.notificationId).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [setNotifications({ notifications: response })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    private notificationsService: NotificationsService
  ) {}
}
