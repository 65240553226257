import libphonenumber from 'google-libphonenumber';
import { fundsAmountsList } from '@fund-base/constants/funds/funds.constants';

export function isNull(value: any) {
  return value === undefined || value === null;
}

export function isNotNull(value: any) {
  return !isNull(value);
}

export const phoneUtility = libphonenumber.PhoneNumberUtil.getInstance();

// fund grants
export function fundGrantRangeToString(minValue: number, maxValue: number) {
  if (minValue === 0 && maxValue === 5000) {
    return '<$4,999';
  } else if (minValue === 1000000 && maxValue === 1000000) {
    return '$1M+';
  } else {
    const minValueText = fundsAmountsList?.find(amount => amount.value === minValue);
    const maxValueText = fundsAmountsList?.find(amount => amount.value === maxValue);
    return `${minValueText?.text}-${maxValueText?.text}`;
  }
}

export function fundGrantRangeToMinMaxValues(fundsGrant: string) {
  if (fundsGrant === '<$4,999') {
    return { minValue: 0, maxValue: 5000 };
  } else if (fundsGrant === '$1M+') {
    return { minValue: 1000000, maxValue: 1000000 };
  } else {
    const valuesInArray = fundsGrant.split('-');
    const minValue = fundsAmountsList.find(amount => amount.text === valuesInArray[0]);
    const maxValue = fundsAmountsList?.find(amount => amount.text === valuesInArray[1]);
    return { minValue: minValue?.value, maxValue: maxValue?.value };
  }
}

export function filtersFormatter(counts: any) {
  let filters: any[] = [];

  filters.push({ categoryKey: 'phone', properties: [{ key: 'phone', count: counts?.phone?.doc_count }] });
  filters.push({ categoryKey: 'email', properties: [{ key: 'email', count: counts?.email?.doc_count }] });
  filters.push({
    categoryKey: 'limitations',
    properties: [{ key: 'limitations', count: counts?.limitations?.doc_count }],
  });
  filters.push({
    categoryKey: 'delegations',
    properties: [{ key: 'delegations', count: counts?.delegation?.doc_count }],
  });
  filters.push({ categoryKey: 'webSite', properties: [{ key: 'webSite', count: counts?.web?.doc_count }] });
  filters.push({
    categoryKey: 'newestFunds',
    properties: [{ key: 'newestFunds', count: counts?.newestFunds?.doc_count }],
  });

  let types: any = [];
  let categories: any = [];
  let months: any = [];

  let typesMap: any = { type_business: 2, type_fund: 1, type_other: 4, type_rfp: 3 };

  Object.keys(counts).forEach(key => {
    if (key.startsWith('type_')) {
      types.push({ key: typesMap[key], count: counts[key]['doc_count'] });
    }

    if (key.startsWith('cat_')) {
      categories.push({ key: key.replace('cat_', ''), count: counts[key]['doc_count'] });
    }

    if (key.startsWith('month_')) {
      months.push({ key: key.replace('month_', ''), count: counts[key]['doc_count'] });
    }
  });

  filters.push({ categoryKey: 'MonthsToApply', properties: months });
  filters.push({ categoryKey: 'type', properties: types });
  filters.push({ categoryKey: 'areaOfInterests', properties: categories });

  return filters;
}
