import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { combineLatestWith, map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { AuthService } from '@fund-base/services/auth/auth.service';
import { authServiceInjectionToken } from '@fund-base/constants/auth/auth.constants';
import {
  canAccessOnboarding,
  checkOrganizationSettings,
  checkUser,
  navigateGuard,
} from '@fund-base/utils/helpers/routing/routing.helpers';
import { OrganizationService } from '@fund-base/services/organization/organization.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    @Inject(authServiceInjectionToken) private authService: AuthService,
    private organizationService: OrganizationService,
    private store: Store,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return checkUser(this.store, this.authService, this.storageService).pipe(
      combineLatestWith(checkOrganizationSettings(this.store, this.organizationService)),
      map(([user, organizationSettings]) => {
        // can access only if the user match the conditions
        const guardCondition = canAccessOnboarding(user);
        if (!guardCondition) {
          navigateGuard(this.router, user, organizationSettings);
        }
        return guardCondition;
      })
    );
  }
}
