import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { UIEffects } from './effects/ui.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { featureUIKey, uiReducer } from './reducers/ui.reducer';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { metaReducers } from './reducers/meta.reducer';
import { featureLocaleKey, localeReducer } from './reducers/locale.reducer';
import { LocaleEffects } from '@fund-base/store/effects/locale.effects';
import { authReducer, featureAuthKey } from '@fund-base/store/reducers/auth.reducer';
import { AuthEffects } from '@fund-base/store/effects/auth.effects';
import { featurePlanKey, planReducer } from '@fund-base/store/reducers/plan.reducer';
import { PlanEffects } from '@fund-base/store/effects/plan.effects';
import { FundsEffects } from '@fund-base/store/effects/funds.effects';
import { featureFundsKey, fundsReducer } from '@fund-base/store/reducers/funds.reducer';
import { featureOrganizationKey, organizationReducer } from '@fund-base/store/reducers/organization.reducer';
import { OrganizationEffects } from '@fund-base/store/effects/organization.effects';
import { featureWorkPlanKey, workplanReducer } from '@fund-base/store/reducers/workplan.reducer';
import { WorkPlanEffects } from '@fund-base/store/effects/workplan.effects';
import { featureTypesKey, typesReducer } from '@fund-base/store/reducers/types.reducer';
import { featureSmartAgentKey, smartAgentReducer } from '@fund-base/store/reducers/smart-agent.reducer';
import { SmartAgentEffects } from '@fund-base/store/effects/smart-agent.effects';
import { UsersEffects } from '@fund-base/store/effects/users.effects';
import { TypesEffects } from '@fund-base/store/effects/types.effects';
import { featureUserKey, userReducer } from '@fund-base/store/reducers/users.reducer';
import { featureNotificationsKey, notificationsReducer } from '@fund-base/store/reducers/notifications.reducer';
import { NotificationsEffects } from '@fund-base/store/effects/notifications.effects';
import { PaymentsEffects } from '@fund-base/store/effects/payments.effects';
import { featurePaymentsKey, paymentsReducer } from '@fund-base/store/reducers/payments.reducer';
import { atlasDataReducer, featureAtlasDataKey } from '@fund-base/store/reducers/atlas-data.reducer';
import { featureLanguagesKey, languagesReducer } from '@fund-base/store/reducers/languages.reducer';
import { LanguagesEffects } from '@fund-base/store/effects/languages.effects';
import { countriesReducer, featureCountriesKey } from '@fund-base/store/reducers/countries.reducer';
import { CountriesEffects } from '@fund-base/store/effects/countries.effects';
import { featurePhilanthropicContentKey, philanthropicContentReducer } from './reducers/philanthropic-content.reducer';
import { PhilanthropicContentEffects } from './effects/philanthropic-content.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {
        [featureUIKey]: uiReducer,
        [featureLocaleKey]: localeReducer,
        [featureAuthKey]: authReducer,
        [featurePlanKey]: planReducer,
        [featureFundsKey]: fundsReducer,
        [featureOrganizationKey]: organizationReducer,
        [featureTypesKey]: typesReducer,
        [featureWorkPlanKey]: workplanReducer,
        [featureSmartAgentKey]: smartAgentReducer,
        [featureUserKey]: userReducer,
        [featureAtlasDataKey]: atlasDataReducer,
        [featureNotificationsKey]: notificationsReducer,
        [featurePaymentsKey]: paymentsReducer,
        [featureLanguagesKey]: languagesReducer,
        [featureCountriesKey]: countriesReducer,
        [featurePhilanthropicContentKey]: philanthropicContentReducer,
      },
      { metaReducers }
    ),
    EffectsModule.forRoot([
      UIEffects,
      LocaleEffects,
      AuthEffects,
      PlanEffects,
      FundsEffects,
      OrganizationEffects,
      WorkPlanEffects,
      SmartAgentEffects,
      UsersEffects,
      TypesEffects,
      NotificationsEffects,
      PaymentsEffects,
      LanguagesEffects,
      CountriesEffects,
      PhilanthropicContentEffects,
    ]),
    StoreDevtoolsModule.instrument(),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class AppStoreModule {}
