import { Action, createReducer, on } from '@ngrx/store';

import { CountriesState, initialCountriesState } from '@fund-base/store/state/countries.state';
import { fetchFundCountries, setFundCountries } from '@fund-base/store/actions/countries.action';

export const featureCountriesKey = 'countries';

const _countriesReducer = createReducer<CountriesState, Action>(
  initialCountriesState,
  on(fetchFundCountries, state => state),
  on(setFundCountries, (state, { fundCountries }) => ({ ...state, fundCountries }))
);

export function countriesReducer(state: CountriesState | undefined, action: Action) {
  return _countriesReducer(state, action);
}
