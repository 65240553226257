import { WorkPlan } from '@fund-base/types/workplan/workplan.types';

export interface WorkPlanState {
  workplan?: WorkPlan;
  loading: boolean;
  workplans?: WorkPlan[];
}

export const initialWorkPlansState: WorkPlanState = {
  workplan: undefined,
  loading: false,
  workplans: [],
};
