import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ErrorService } from '@fund-base/services/error/error.service';
import { catchError, switchMap } from 'rxjs';
import { showToast } from '../actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { TranslocoService } from '@ngneat/transloco';
import {
  createSmartAgent,
  deleteSmartAgent,
  deleteSmartAgentMail,
  fetchSmartAgents,
  setSmartAgentLoading,
  setSmartAgents,
  updateSmartAgent,
} from '@fund-base/store/actions/smart-agent.actions';
import { SmartAgentService } from '@fund-base/services/smart-agent/smart-agent.service';

@Injectable()
export class SmartAgentEffects {
  createSmartAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSmartAgent),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setSmartAgentLoading({ loading: true }));

        // call  from service
        return this.smartAgentService.createSmartAgent(action?.SmartAgent).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setSmartAgentLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Automatic search created successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setSmartAgentLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  updateSmartAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSmartAgent),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setSmartAgentLoading({ loading: true }));

        // call  from service
        return this.smartAgentService.updateSmartAgent(action?.SmartAgent).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setSmartAgentLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Automatic search updated successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setSmartAgentLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  deleteSmartAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSmartAgent),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setSmartAgentLoading({ loading: true }));

        // call  from service
        return this.smartAgentService.deleteSmartAgent(action?.smartAgentID).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setSmartAgentLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('The automatic search has been successfully deleted'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setSmartAgentLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  fetchSmartAgents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSmartAgents),
      switchMap(action => {
        this.store.dispatch(setSmartAgentLoading({ loading: true }));

        // return this.smartAgentService.getAllSmartAgents(action.params)
        return this.smartAgentService.getSmartAgentByQuery(action.params).pipe(
          switchMap(response => {
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            return [setSmartAgentLoading({ loading: false }), setSmartAgents({ smartAgents: response })];
          }),
          catchError(errorRes => {
            const error = this.errorService.getErrorMessage(errorRes);

            if (!!action?.onError) action?.onError?.call(error);

            return [setSmartAgentLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  deleteSmartAgentMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSmartAgentMail),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setSmartAgentLoading({ loading: true }));

        // call  from service
        return this.smartAgentService.deleteSmartAgentMail(action?.smartAgentMailId).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setSmartAgentLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Smart Agent Mail deleted successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setSmartAgentLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private errorService: ErrorService,
    private translocoService: TranslocoService,
    private smartAgentService: SmartAgentService
  ) {}
}
