import { Action, createReducer, on } from '@ngrx/store';

import { initialLanguagesState, LanguagesState } from '@fund-base/store/state/languages.state';
import { fetchFundLanguages, setFundLanguages } from '@fund-base/store/actions/languages.action';

export const featureLanguagesKey = 'languages';

const _languagesReducer = createReducer<LanguagesState, Action>(
  initialLanguagesState,
  on(fetchFundLanguages, state => state),
  on(setFundLanguages, (state, { fundLanguages }) => ({ ...state, fundLanguages }))
);

export function languagesReducer(state: LanguagesState | undefined, action: Action) {
  return _languagesReducer(state, action);
}
