import { createAction, props } from '@ngrx/store';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { FundCountries } from '@fund-base/types/countries/countries.types';

// loading
export const setCountriesLoading = createAction('[Countries] Set countries loading', props<{ loading: boolean }>());

// fund countries
export const fetchFundCountries = createAction(
  '[Countries] Fetch fund countries',
  props<{ onSuccess?: SuccessCallback; onError?: ErrorCallback }>()
);

export const setFundCountries = createAction(
  '[Countries] Set fund countries',
  props<{ fundCountries: FundCountries[] }>()
);
