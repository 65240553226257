import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasePhilanthropicContentRoute } from '@fund-base/constants/philanthropic-content/philanthropic-content.constants';
import { PhilanthropicContent, Tags } from '@fund-base/types/philanthropic-content/philanthropic-content.types';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhilanthropicContentService {
  constructor(private httpClient: HttpClient) {}

  getPhilanthropicContentList(tags: Tags[] = [], page: number = 0, pageSize: number = 10) {
    const url = new URL(BasePhilanthropicContentRoute);
    if (tags.length > 0) {
      url.searchParams.append('tags', tags.join(','));
    }
    url.searchParams.append('page', page.toString());
    url.searchParams.append('pageSize', pageSize.toString());
    return this.httpClient.get<{ content: PhilanthropicContent[]; hasMore: boolean }>(url.toString()).pipe(
      catchError(error => {
        return throwError(() => new Error(this.getErrorMessage(error)));
      })
    );
  }

  getPhilanthropicContentDetails(id: string) {
    return this.httpClient.get<PhilanthropicContent>(`${BasePhilanthropicContentRoute}/${id}`).pipe(
      catchError(error => {
        return throwError(() => new Error(this.getErrorMessage(error)));
      })
    );
  }

  updateBreakpoint(id: string, index: number, isWatched: boolean) {
    return this.httpClient
      .put<any>(`${BasePhilanthropicContentRoute}/${id}/breakpoint`, {
        idx: index,
        isWatched: isWatched,
      })
      .pipe(
        catchError(error => {
          return throwError(() => new Error(this.getErrorMessage(error)));
        })
      );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    console.error(`PhilanthropicContentService error | status: ${error.status}`, error.error);
    if (error.status === 404) {
      return error.error.message || 'Content not found.';
    } else if (error.status === 500) {
      return error.error.message || 'Server error occurred. Please try again later.';
    } else {
      return error.error.message || 'An unexpected error occurred. Please try again.';
    }
  }
}
