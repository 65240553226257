import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { TranslocoService } from '@ngneat/transloco';
import { selectLanguage } from '@fund-base/store/selectors/locale.selectors';
import { fetchFundCountries, setCountriesLoading, setFundCountries } from '@fund-base/store/actions/countries.action';
import { CountriesService } from '@fund-base/services/countries/countries.service';

@Injectable()
export class CountriesEffects {
  // fetch fund countries
  fetchFundCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFundCountries),
      withLatestFrom(this.store.select(selectLanguage)),
      switchMap(([action]) => {
        // set fund countries loading
        this.store.dispatch(setCountriesLoading({ loading: true }));

        // call authenticate from service
        return this.countriesService.getFundCountries().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setFundCountries({ fundCountries: response.fundCountries ?? [] }),
              setCountriesLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setCountriesLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    private translocoService: TranslocoService,
    private countriesService: CountriesService
  ) {}
}
