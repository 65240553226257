import { Injectable } from '@angular/core';
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { first, flatMap } from 'rxjs/operators';
import { selectToken } from '@fund-base/store/selectors/auth.selectors';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { authTokenLocalStorageKey } from '@fund-base/constants/auth/auth.constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store, private storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return this.store.select(selectToken).pipe(
      first(),
      flatMap(token => {
        if (!token) {
          token = this.storageService.getItem(authTokenLocalStorageKey);
        }
        if (!!token) {
          req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
        }
        return next.handle(req);
      })
    );
  }
}
