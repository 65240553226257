import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { adminFundCountriesApiUrl } from '@fund-base/constants/admin/admin.constants';
import { FundCountries } from '@fund-base/types/countries/countries.types';

@Injectable({ providedIn: 'root' })
export class CountriesService {
  constructor(private httpClient: HttpClient) {}

  // get funds countries
  getFundCountries(): Observable<{ fundCountries: FundCountries[] }> {
    return this.httpClient.get<{ fundCountries: FundCountries[] }>(`${adminFundCountriesApiUrl}/`);
  }
}
