import { Injectable } from '@angular/core';
import { User } from '@fund-base/types/auth/auth.types';
import { mockAuthToken, mockUser } from '@fund-base/services/auth/auth.mock.data';
import { delay, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthMockService {
  constructor() {}

  // login
  login(username: string, password: string): Observable<{ user?: User; token?: string }> {
    return of({
      user: mockUser,
      token: mockAuthToken,
    }).pipe(delay(3000));
  }

  // signup
  signup(user: User): Observable<{ user?: User; token?: string }> {
    return of({
      user: mockUser,
      token: mockAuthToken,
    });
  }

  // authenticate
  authenticate(token: string): Observable<{ user?: User; token?: string }> {
    return of({
      user: mockUser,
      token: mockAuthToken,
    });
  }
}
