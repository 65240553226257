import { FundCountries } from '@fund-base/types/countries/countries.types';

export interface CountriesState {
  loading: boolean;
  fundCountries: FundCountries[];
}

export const initialCountriesState: CountriesState = {
  loading: false,
  fundCountries: [],
};
