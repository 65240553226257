import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  addNewUserApiUrl,
  deleteInvoiceApiUrl,
  getOrganizationSettingsApiUrl,
  getOrganizationUsersListApiUrl,
  updateOrganizationOnboardingCompletedApiUrl,
  updateOrganizationSettingsApiUrl,
} from '@fund-base/constants/organization/organization.constants';
import { CreatNewUser, Organization, OrganizationSettings } from '@fund-base/types/organization/organization.types';
import { User } from '@fund-base/types/auth/auth.types';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  constructor(private httpClient: HttpClient) {}

  // get organization settings
  getOrganizationSettings(lang: string): Observable<{ organizationSettings?: OrganizationSettings }> {
    return this.httpClient.put<{}>(getOrganizationSettingsApiUrl, { lang: lang });
  }

  // set organization details
  updateOrganizationSettings(
    organizationSettings?: OrganizationSettings
  ): Observable<{ organizationSettings?: OrganizationSettings; user?: { user?: User; accessToken?: string } }> {
    return this.httpClient.put<{}>(updateOrganizationSettingsApiUrl, organizationSettings);
  }

  // create new user
  addNewUser(newUser?: CreatNewUser): Observable<{ user?: User }> {
    return this.httpClient.post<{ user?: User }>(addNewUserApiUrl, { newUser: newUser });
  }

  // set organization onboarding completed
  updateOnboardingCompleted(completed: boolean): Observable<{ organization: Organization }> {
    return this.httpClient.put<{ organization: Organization }>(updateOrganizationOnboardingCompletedApiUrl, {
      completed: completed,
    });
  }

  getOrganizationUsersList(): Observable<{ data: User[] | void }> {
    return this.httpClient.get<{ data: User[] | void }>(getOrganizationUsersListApiUrl);
  }

  deleteInvoice(invoiceId: number) {
    return this.httpClient.delete<boolean>(deleteInvoiceApiUrl + '/' + invoiceId);
  }
}
