import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { PaymentsService } from '@fund-base/services/payments/payments.service';
import { fetchInvoices, setInvoices, setPaymentLoading } from '@fund-base/store/actions/payments.actions';

@Injectable()
export class PaymentsEffects {
  // fetch Invoices:
  fetchInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchInvoices),
      switchMap(action => {
        // set plan loading
        this.store.dispatch(setPaymentLoading({ loading: true }));

        // call authenticate from service
        return this.paymentsService
          .getAllInvoices() //TODO: check if token is necessary
          .pipe(
            switchMap(response => {
              // on success callback
              if (!!action?.onSuccess) action?.onSuccess?.call(response);
              // on success
              return [setInvoices({ invoices: response?.invoicesList }), setPaymentLoading({ loading: false })];
            }),
            catchError(errorRes => {
              // get error
              const error = this.errorService.getErrorMessage(errorRes);

              // on error callback
              if (!!action?.onError) action?.onError?.call(error);

              // on error
              return [setPaymentLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    private paymentsService: PaymentsService
  ) {}
}
