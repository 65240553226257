import { baseApiUrl, baseGoogleUrl } from '@fund-base/constants/general/general.constants';

export const authBaseApiUrl = `${baseApiUrl}/auth`;
export const authLoginGoogleApiUrl = `${authBaseApiUrl}/google/login`;
export const authSignupGoogleApiUrl = `${authBaseApiUrl}/google/signup/user`;
export const authGoogleApiUrl = `${authBaseApiUrl}/google/auth`;
export const googleCalendarApiUrl = `${authBaseApiUrl}/calendar`;
export const googlePlacesApiUrl = `${baseApiUrl}/search/places`;
export const googleApiTokenInfoUri = `https://oauth2.googleapis.com/tokeninfo`;

//google api
export const googleClientId = baseGoogleUrl;
export const googleSignInScopes = 'openid profile email https://www.googleapis.com/auth/user.phonenumbers.read';
export const googleCalendarScopes =
  'openid profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events';
