import { InjectionToken } from '@angular/core';
import { baseApiUrl } from '@fund-base/constants/general/general.constants';

// funds api
export const fundsBaseApiUrl = `${baseApiUrl}/funds`;
export const fundsSearchApiUrl = `${baseApiUrl}/search`;
export const fundsSearchFiltersCountApiUrl = `${baseApiUrl}/search/filters-count`;
export const fundsSearchVisitorsApiUrl = `${baseApiUrl}/search/visitors`;
export const fundsSearchAfterApiUrl = `${fundsSearchApiUrl}/after`;
export const fundsSearchHistoryApiUrl = `${fundsSearchApiUrl}/history`;
export const fundDetailsApiUrl = `${fundsBaseApiUrl}/fund-details`;
export const fundVisitorDetailsApiUrl = `${fundsBaseApiUrl}/visitors/fund-details`;
export const fundFirstVisitorDetailsApiUrl = `${fundsBaseApiUrl}/visitors-first/fund-details`;
export const fundVisitorNumberApiUrl = `${fundsBaseApiUrl}/visitors/fund-number`;
export const fundAmountByCategoriesApiUrl = `${fundsBaseApiUrl}/amount-by-categories-details`;
export const fundAmountByCategoriesFirstApiUrl = `${fundsBaseApiUrl}/amount-by-categories-details-first`;
export const fundAmountByGrantsApiUrl = `${fundsBaseApiUrl}/amount-by-grant-details`;
export const fundAmountByGrantsFirstApiUrl = `${fundsBaseApiUrl}/amount-by-grant-details-first`;
export const sourceFundsApiUrl = `${baseApiUrl}/funds/source-funds`;
export const lastMonthFundsCountApiUrl = `${baseApiUrl}/funds/newest-funds`;
export const lastMonthFundsApiUrl = `${baseApiUrl}/funds/latest-funds`;
export const lastMonthSearchApiUrl = `${baseApiUrl}/search/month-history`;

export const fundsChoosingApiUrl = `${baseApiUrl}`;

export const fundingExpertsListApiUrl = `${baseApiUrl}/funding-experts/list`;

export const shareFundApiUrl = `${baseApiUrl}/funds/share-fund`;

// funds service injection token
export const fundsServiceInjectionToken = new InjectionToken('funds.service');

// funds local storage keys
export const fundsLocalStorageKey = 'funds';

// funds amounts
export const fundsAmountsList = [
  { value: 0, text: '$0' },
  { value: 5000, text: '$5K' },
  { value: 10000, text: '$10K' },
  { value: 25000, text: '$25K' },
  { value: 50000, text: '$50K' },
  { value: 100000, text: '$100K' },
  { value: 500000, text: '$500K' },
  { value: 1000000, text: '$1M' },
];

// funding experts languages
export const fundingExpertsLanguagesList = [
  { value: 'he', text: 'Hebrew' },
  { value: 'en', text: 'English' },
];

// funding experts countries
export const fundingExpertsCountriesList = [
  { value: 'IL', text: 'Israel' },
  { value: 'US', text: 'United States' },
];

// funding experts price ranges
export const fundingExpertsPriceRangesList = [
  { minValue: 0, maxValue: 50, text: '0$-50$' },
  { minValue: 50, maxValue: 100, text: '50$-100$' },
  { minValue: 100, maxValue: Number.MAX_SAFE_INTEGER, text: '100$+' },
];

// funds filters categories:
export const sideBarFilterCategories = [
  { title: 'Email', value: 'email', properties: [{ title: 'Email', value: 'email' }] },
  { title: 'Limitations', value: 'limitations', properties: [{ title: 'Limitations', value: 'limitations' }] },
  { title: 'Delegations', value: 'delegations', properties: [{ title: 'Delegations', value: 'delegations' }] },
  { title: 'Web Site', value: 'webSite', properties: [{ title: 'Web Site', value: 'webSite' }] },
  { title: 'Phone Number', value: 'phone', properties: [{ title: 'Phone Number', value: 'phone' }] },
  { title: 'Newest Funds', value: 'newestFunds', properties: [{ title: 'Newest Funds', value: 'newestFunds' }] },
  { title: 'Type', value: 'type', properties: [] },
  { title: 'Area Of Interests', value: 'areaOfInterests', properties: [] },
  {
    title: 'Months To Apply',
    value: 'monthsToApply',
    properties: [
      { title: 'All Months', value: '0' },
      { title: 'January', value: '1' },
      { title: 'February', value: '2' },
      { title: 'March', value: '3' },
      { title: 'April', value: '4' },
      { title: 'May', value: '5' },
      { title: 'June', value: '6' },
      { title: 'July', value: '7' },
      { title: 'August', value: '8' },
      { title: 'September', value: '9' },
      { title: 'October', value: '10' },
      { title: 'November', value: '11' },
      { title: 'December', value: '12' },
    ],
  },
];
