import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import * as ContentActions from '../actions/philanthropic-content.actions';
import { PhilanthropicContentService } from '@fund-base/services/philanthropic-content/philanthropic-content.service';

@Injectable()
export class PhilanthropicContentEffects {
  loadContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentActions.loadContent),
      mergeMap(({ tags, page, pageSize, isReplace }) => {
        return this.philanthropicContentService.getPhilanthropicContentList(tags, page, pageSize).pipe(
          map(response => {
            return ContentActions.loadContentSuccess({
              page: page,
              content: response.content,
              hasMore: response.hasMore,
              isReplace: isReplace,
            });
          }),
          catchError(error => of(ContentActions.loadContentFailure({ error: error.message })))
        );
      })
    )
  );

  constructor(private actions$: Actions, private philanthropicContentService: PhilanthropicContentService) {}
}
