import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, switchMap, withLatestFrom } from 'rxjs';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { setGlobalLoading, showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { FundsService } from '@fund-base/services/funds/funds.service';
import {
  fetchFundingExperts,
  fetchFunds,
  fetchFundsAfter,
  fetchFundsThroughHistory,
  fetchFundsVisitors,
  fetchMoreFundingExperts,
  setAdvancedFilters,
  setFundingExperts,
  setFunds,
  setFundsFilterCount,
  setFundsFilterCountLoading,
  setFundsLoading,
  setFundsSearch,
  setUserHistory,
  setFundsPage,
  sendShareFund,
  fetchNumberFundsVisitors,
} from '@fund-base/store/actions/funds.actions';
import { fundsServiceInjectionToken } from '@fund-base/constants/funds/funds.constants';
import {
  selectFundingExperts,
  selectFunds,
  selectFundsCount,
  selectFundsFilters,
  selectFundsPage,
  selectFundsSearchQuery,
} from '@fund-base/store/selectors/funds.selectors';
import { filtersFormatter } from '@fund-base/utils/helpers/general/general.helpers';
import { setLanguage } from '../actions/locale.actions';
import { setFundLanguages } from '../actions/languages.action';

@Injectable()
export class FundsEffects {
  // fetch Funds
  fetchFunds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFunds),
      switchMap(action => {
        // set Funds loading
        this.store.dispatch(setGlobalLoading({ loading: true }));
        this.store.dispatch(setAdvancedFilters({ filtersSearch: action?.filtersSearch }));
        return this.fundsService.getFunds(action?.filtersSearch, 0, 10).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setFundsPage({ page: response.page }),
              setUserHistory({
                usersSearchHistory: response?.usersSearchHistory,
              }),
              setFundsSearch({
                searchQuery: response.searchQuery,
              }),
              setFunds({
                fundsList: response.fundsList,
                fundsCount: response.fundsCount,
                filtersCount: filtersFormatter(response.filtersCount),
                page: response.page,
                size: response.size,
              }),
              setGlobalLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setGlobalLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );
  // fetchFundsFiltersCount$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(fetchFundsFilterCount),
  //     switchMap((action) => {
  //         // set Funds loading
  //         this.store.dispatch(setFundsFilterCountLoading({filtersCountLoading: true}));
  //         return this.fundsService.getFundsFiltersCount(action?.query)
  //           .pipe(
  //             switchMap(response => {
  //               // on success callback
  //               if (!!action?.onSuccess) action?.onSuccess(response);
  //               // on success
  //               return [
  //                 setFundsFilterCount({
  //                   filtersFromQueries: response.filtersFromQueries
  //                 }),
  //                 setFundsFilterCountLoading({filtersCountLoading: false}),
  //               ]
  //             }),
  //             catchError(errorRes => {
  //               // get error
  //               const error = this.errorService.getErrorMessage(errorRes);

  //               // on error callback
  //               if (!!action?.onError) action?.onError?.call(error);

  //               // on error
  //               return [
  //                 setFundsFilterCountLoading({filtersCountLoading: false}),
  //                 showToast({content: error, status: ToastType.failure})
  //               ];
  //             })
  //           );
  //       }
  //     )
  //   )
  // );

  // fetch Funds visitors
  fetchFundsVisitors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFundsVisitors),
      switchMap(action => {
        // set Funds loading
        this.store.dispatch(setGlobalLoading({ loading: true }));
        this.store.dispatch(setAdvancedFilters({ filtersSearch: action?.filtersSearch }));
        return this.fundsService.getFundsVisitors(action?.filtersSearch).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setFundsPage({ page: response.page }),
              setFundsSearch({
                searchQuery: response.searchQuery,
              }),
              setFunds({
                fundsList: response.fundsList,
                fundsCount: response.fundsCount,
                filtersCount: filtersFormatter(response.filtersCount),
                page: response.page,
                size: response.size,
              }),
              setGlobalLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setGlobalLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // fetch Number Funds visitors
  fetchNumberFundsVisitors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNumberFundsVisitors),
      switchMap(action => {
        // set Funds loading
        this.store.dispatch(setGlobalLoading({ loading: true }));
        return this.fundsService.getNumberFundsVisitors().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [setGlobalLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setGlobalLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // fetch Funds Through History
  fetchFundsThroughHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFundsThroughHistory),
      switchMap(action => {
        // set Funds loading
        this.store.dispatch(setGlobalLoading({ loading: true }));
        this.store.dispatch(setAdvancedFilters({ filtersSearch: action?.filtersSearch }));
        return this.fundsService.getFundsThroughHistory(action?.filtersSearch, action?.searchQuery).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setFunds({
                fundsList: response.fundsList,
                fundsCount: response.fundsCount,
                filtersCount: filtersFormatter(response.filtersCount),
                page: 0,
                size: 10,
              }),
              setGlobalLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setGlobalLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  fetchFundsAfter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFundsAfter),
      withLatestFrom(
        this.store.select(selectFundsSearchQuery),
        this.store.select(selectFundsCount),
        this.store.select(selectFundsFilters),
        this.store.select(selectFunds),
        this.store.select(selectFundsPage)
      ),
      switchMap(([action, selectQuery, selectFundsCount, selectFundsFilters, funds]) => {
        // set Funds loading
        this.store.dispatch(setFundsLoading({ loading: true }));
        return this.fundsService.getFunds(action?.filtersSearch, action?.page, action?.size).pipe(
          switchMap(response => {
            if (!!action?.onSuccess) action?.onSuccess(response?.fundsList);
            if (funds) {
              const newFunds = [...funds, ...response?.fundsList];
              // on success
              selectFundsCount = selectFundsCount ? selectFundsCount : 0;
              selectFundsFilters = selectFundsFilters ? selectFundsFilters : [];
              return [
                setFundsPage({ page: response.page }),
                setFunds({
                  fundsCount: response.fundsCount,
                  filtersCount: filtersFormatter(response.filtersCount),
                  page: response.page,
                  size: response.size,
                  fundsList: newFunds,
                }),
                setFundsLoading({ loading: false }),
              ];
            } else {
              return EMPTY;
            }
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setFundsLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // fetch funding experts
  fetchFundingExperts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFundingExperts),
      switchMap(action => {
        // set Funds loading
        this.store.dispatch(setFundsLoading({ loading: true }));
        return this.fundsService.getFundingExperts(action?.filters, action?.pagination).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response?.fundingExpertsList);

            // on success
            return [
              setFundingExperts({
                fundingExperts: {
                  fundingExpertsList: response?.fundingExpertsList,
                  totalCount: response?.totalCount,
                },
              }),
              setFundsLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setFundsLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );
  // fetch funding experts
  fetchMoreFundingExperts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMoreFundingExperts),
      withLatestFrom(this.store.select(selectFundingExperts)),
      switchMap(([action, fundingExpertsList$]) => {
        // set Funds loading
        this.store.dispatch(setFundsLoading({ loading: true }));
        return this.fundsService.getFundingExperts(action?.filters, action?.pagination).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response?.fundingExpertsList);

            // on success
            if (!!fundingExpertsList$) {
              response?.fundingExpertsList.push(...fundingExpertsList$);
            }
            return [
              setFundingExperts({
                fundingExperts: {
                  fundingExpertsList: response?.fundingExpertsList,
                  totalCount: response?.totalCount,
                },
              }),
              setFundsLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setFundsLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  shareFund$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendShareFund),
      switchMap(action => {
        // get token

        // set auth loading
        this.store.dispatch(setFundsLoading({ loading: true }));

        // call authenticate from service
        return this.fundsService.shareFund(action?.body, action.languate).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [setFundsLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setFundsLoading({ loading: false })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    @Inject(fundsServiceInjectionToken) private fundsService: FundsService
  ) {}
}
