import { Action, createReducer, on } from '@ngrx/store';
import { initialSmartAgentState, SmartAgentState } from '@fund-base/store/state/smart-agent';
import {
  fetchSmartAgents,
  setSmartAgent,
  setSmartAgentLoading,
  setSmartAgents,
} from '@fund-base/store/actions/smart-agent.actions';

export const featureSmartAgentKey = 'smartAgent';

const _smartAgentReducer = createReducer<SmartAgentState, Action>(
  initialSmartAgentState,
  on(fetchSmartAgents, state => state),
  on(setSmartAgentLoading, (state, { loading }) => ({ ...state, loading })),
  on(setSmartAgent, (state, { smartAgent }) => ({ ...state, smartAgent })),
  on(setSmartAgents, (state, { smartAgents }) => ({ ...state, smartAgents }))
);

export function smartAgentReducer(state: SmartAgentState | undefined, action: Action) {
  return _smartAgentReducer(state, action);
}
