import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notification } from '@fund-base/types/notifications/notifications.types';
import {
  notificationsApiUrl,
  readNotificationsApiUrl,
} from '@fund-base/constants/notifications/notifications.constant';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private httpClient: HttpClient) {}

  getNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(notificationsApiUrl);
  }

  readNotifications(id: number): Observable<Notification[]> {
    return this.httpClient.put<Notification[]>(`${readNotificationsApiUrl}/${id}`, {});
  }
}
