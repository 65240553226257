import { createAction, props } from '@ngrx/store';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { Invoice } from '@fund-base/types/payment/payment.types';

export const setPaymentLoading = createAction('[Payment] Set Payment Loading', props<{ loading: boolean }>());

export const fetchInvoices = createAction(
  '[Payment] Fetch Invoices',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const setInvoices = createAction('[Payment] Set Invoices', props<{ invoices?: Invoice[] }>());
