import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { TranslocoService } from '@ngneat/transloco';
import { selectLanguage } from '@fund-base/store/selectors/locale.selectors';
import { LanguagesService } from '@fund-base/services/languages/languages.service';
import { fetchFundLanguages, setFundLanguages, setLanguagesLoading } from '@fund-base/store/actions/languages.action';

@Injectable()
export class LanguagesEffects {
  // fetch fund languages
  fetchFundLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFundLanguages),
      withLatestFrom(this.store.select(selectLanguage)),
      switchMap(([action, languageFromStore]) => {
        // set fund languages loading
        this.store.dispatch(setLanguagesLoading({ loading: true }));

        // call authenticate from service
        return this.languagesService.getFundLanguages(languageFromStore).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setFundLanguages({ fundLanguages: response.fundLanguages ?? [] }),
              setLanguagesLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setLanguagesLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    private translocoService: TranslocoService,
    private languagesService: LanguagesService
  ) {}
}
