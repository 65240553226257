import { UserRoleEnum } from '@fund-base/shared/enums/user-role.enum';
import { OrganizationOnboardingStep, OrganizationSettings } from '@fund-base/types/organization/organization.types';
import { Router } from '@angular/router';
import { User, Visitor } from '@fund-base/types/auth/auth.types';
import { Store } from '@ngrx/store';
import { AuthService } from '@fund-base/services/auth/auth.service';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { combineLatestWith, Observable, of, throwError } from 'rxjs';
import { selectToken, selectUser } from '@fund-base/store/selectors/auth.selectors';
import { catchError, switchMap } from 'rxjs/operators';
import { authTokenLocalStorageKey, authUserLocalStorageKey } from '@fund-base/constants/auth/auth.constants';
import { setToken, setUser, setVisitor } from '@fund-base/store/actions/auth.actions';
import { selectOrganizationSettings } from '@fund-base/store/selectors/organization.selector';
import { OrganizationService } from '@fund-base/services/organization/organization.service';
import { setOrganizationSettings } from '@fund-base/store/actions/organization.actions';
import { selectLanguage } from '@fund-base/store/selectors/locale.selectors';

// can access admin
export function canAccessAdmin(user?: User): boolean {
  // user verified
  const userVerified = !!user?.verified && user?.status == 1;
  // there is at least one membership initialized
  const userHasValidMembership = !!user?.organization?.membership?.find(membership => membership?.initialized);
  // user has valid organization
  const userHasValidOrganization = !!user?.organization?.initialized && user?.organization?.status == 1;
  return userVerified && userHasValidMembership && userHasValidOrganization;
}

// can access auth
export function canAccessAuth(user?: User): boolean {
  return !canAccessAdmin(user);
}

export function canAccessVisitor(visitor?: Visitor): boolean {
  return true;
  // if (visitor === undefined) return true;
  // else if (
  //   !Object.keys(visitor).includes('organization')
  // ) return true;
  // else if (Object.keys(visitor).includes('organization')) return false;
  // else return false;
}

export function canAccessVisitorWithEmailVerification(visitor?: Visitor): boolean {
  return !!visitor && !visitor.verified;
}

export function canAccessVisitorWithEmail(visitor?: Visitor): boolean {
  return !!visitor && !!visitor.verified;
}

// can access on boarding
export function canAccessOnboarding(user?: User): boolean {
  // user role is admin
  const userRoleIsAdmin = user?.role === UserRoleEnum.ADMIN;
  return canAccessAdmin(user) && userRoleIsAdmin;
}

// can access login
export function canAccessAuthLoginPage(user?: User): boolean {
  // can access only if user is not exists
  return !user;
}

// can access signup
export function canAccessAuthSignUpPage(user?: User): boolean {
  // can access only if user is not exists
  return !user;
}

// can access email verification
export function canAccessAuthEmailVerificationPage(user?: User): boolean {
  // can access only if user exists and not verified
  return !!user && !user?.verified;
}

// can access create organization
export function canAccessAuthCreateOrganizationPage(user?: User): boolean {
  // user has valid organization
  // const userHasValidOrganization = !!user?.organization?.owner_email;
  //
  // // can access only if user exists and does not have valid organization
  // return !!user && (!userHasValidOrganization);
  // there is at least one membership initialized
  const userHasValidMembership = !!user?.organization?.membership?.find(membership => membership?.initialized);

  // can access only if user exists and does not have valid membership
  return !!user && !userHasValidMembership;
}

// can access auth plan
export function canAccessAuthPlanPage(user?: User): boolean {
  // there is at least one membership initialized
  const userHasValidMembership = !!user?.organization?.membership?.find(membership => membership?.initialized);

  // can access only if user exists and does not have valid membership
  return !!user && !userHasValidMembership;
}

// get organization onboarding step
export function getOrganizationOnboardingStep(
  user?: User,
  organizationSettings?: OrganizationSettings
): OrganizationOnboardingStep {
  if (user?.organization?.on_boarding_completed) {
    return OrganizationOnboardingStep.completed;
  } else if (!organizationSettings?.interests?.length) {
    return OrganizationOnboardingStep.areaOfInterests;
  } else if (!organizationSettings?.locations?.length) {
    return OrganizationOnboardingStep.areaOfActivities;
  } else if (!organizationSettings?.range) {
    return OrganizationOnboardingStep.fundingRange;
  } else {
    return OrganizationOnboardingStep.completed;
  }
}

// guarded navigate
export function navigateGuard(router: Router, user?: User, organizationSettings?: OrganizationSettings) {
  if (canAccessAuth(user)) {
    if (canAccessAuthLoginPage(user)) {
      router.navigate(['auth/login']);
    } else if (canAccessAuthSignUpPage(user)) {
      router.navigate(['auth/signup/details']);
    } else if (canAccessAuthEmailVerificationPage(user)) {
      router.navigate(['auth/signup/email-verification']);
    } else if (canAccessAuthCreateOrganizationPage(user)) {
      router.navigate(['auth/signup/plan']);
    } else if (canAccessAuthPlanPage(user)) {
      router.navigate(['auth/signup/plan']);
    }
  } else if (canAccessOnboarding(user)) {
    const organizationOnboardingStep = getOrganizationOnboardingStep(user, organizationSettings);
    switch (organizationOnboardingStep) {
      case OrganizationOnboardingStep.areaOfInterests:
        router.navigate(['onboarding/main/welcome']);
        break;
      case OrganizationOnboardingStep.areaOfActivities:
        router.navigate(['onboarding/main/areas-of-activities']);
        break;
      case OrganizationOnboardingStep.fundingRange:
        router.navigate(['onboarding/main/funding-sums']);
        break;
      case OrganizationOnboardingStep.completed:
        router.navigate(['admin']);
        break;
      default:
        router.navigate(['admin']);
        break;
    }
  } else if (canAccessAdmin(user)) {
    router.navigate(['admin']);
  } else {
    router.navigate(['auth']);
  }
}

// check if user exist is store or in local storage, if not fetch from server
export function checkUser(
  store: Store,
  authService: AuthService,
  storageService: StorageService
): Observable<User | undefined> {
  return store.select(selectToken).pipe(
    combineLatestWith(store.select(selectUser)),
    switchMap(([tokenFromStore, userFromStore]) => {
      const user: User | undefined = userFromStore || storageService.getItem(authUserLocalStorageKey);
      const token: string | undefined = tokenFromStore || storageService.getItem(authTokenLocalStorageKey);
      if (!!user) {
        return of(user);
      } else if (!token) {
        return of(undefined);
      } else {
        return authService.authenticate().pipe(
          switchMap(response => {
            if (!!response) {
              store.dispatch(setToken({ token: response?.accessToken }));
              store.dispatch(setUser({ user: response?.user }));
              return of(response?.user);
            } else {
              return throwError(null);
            }
          }),
          catchError(error => {
            return of(undefined);
          })
        );
      }
    })
  );
}

export function checkVisitor(
  store: Store,
  authService: AuthService,
  storageService: StorageService
): Observable<Visitor | undefined> {
  return store.select(selectToken).pipe(
    combineLatestWith(store.select(selectUser)),
    switchMap(([tokenFromStore, userFromStore]) => {
      const user: User | undefined = userFromStore || storageService.getItem(authUserLocalStorageKey);
      const token: string | undefined = tokenFromStore || storageService.getItem(authTokenLocalStorageKey);
      if (!!user) {
        return of(user);
      } else if (!token) {
        return of(undefined);
      } else {
        return authService.authenticateVisitor().pipe(
          switchMap(response => {
            if (!!response) {
              store.dispatch(setToken({ token: response?.accessToken }));
              store.dispatch(setVisitor({ visitor: response?.user }));
              return of(response?.user);
            } else {
              return throwError(null);
            }
          }),
          catchError(error => {
            return of(undefined);
          })
        );
      }
    })
  );
}

// check if organization settings exist is store, if not fetch from server
export function checkOrganizationSettings(
  store: Store,
  organizationService: OrganizationService
): Observable<OrganizationSettings | undefined> {
  return store.select(selectOrganizationSettings).pipe(
    combineLatestWith(store.select(selectLanguage), store.select(selectUser)),
    switchMap(([organizationSettingsFromStore, lang, user]) => {
      if (!!organizationSettingsFromStore) {
        return of(organizationSettingsFromStore);
      } else if (!!user) {
        return organizationService.getOrganizationSettings(lang).pipe(
          switchMap(response => {
            if (!!response) {
              store.dispatch(setOrganizationSettings({ organizationSettings: response?.organizationSettings }));
              return of(response?.organizationSettings);
            } else {
              return throwError(null);
            }
          }),
          catchError(error => {
            return of(undefined);
          })
        );
      } else return of(undefined);
    })
  );
}
