import { createAction, props } from '@ngrx/store';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { Organization, OrganizationSettings } from '@fund-base/types/organization/organization.types';
import { User } from '@fund-base/types/auth/auth.types';
import { OrganizationUsersCommentsFunds } from '@fund-base/types/users/organization-users-comments.types';

// set organization settings
export const setOrganization = createAction(
  '[Organization] Set organization',
  props<{ organization?: Organization; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set organization settings
export const setOrganizationSettings = createAction(
  '[Organization] Set organization settings',
  props<{ organizationSettings?: OrganizationSettings; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// fetch organization settings
export const fetchOrganizationSettings = createAction(
  '[Organization] Fetch organization settings',
  props<{ organizationSettings?: OrganizationSettings; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// update organization settings
export const updateOrganizationSettings = createAction(
  '[Organization] Update organization settings',
  props<{ organizationSettings?: OrganizationSettings; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set on boarding loading
export const setOrganizationLoading = createAction(
  '[Organization] Set organization loading',
  props<{ loading: boolean }>()
);

// set on boarding completed
export const updateOrganizationOnboardingSkipped = createAction(
  '[Organization] Update organization onboarding completed',
  props<{ completed: boolean; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// fetch users list
export const fetchUsersList = createAction(
  '[Organization] Fetch users List',
  props<{ organizationUsersList?: User[]; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set users list
export const setUsersList = createAction(
  '[Organization] Set users List',
  props<{ organizationUsersList?: User[]; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// fetch organization comments list
export const fetchOrganizationCommentsList = createAction(
  '[Organization] Fetch organization comments list',
  props<{
    organizationComments?: OrganizationUsersCommentsFunds[];
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// set organization comments list
export const setOrganizationCommentsList = createAction(
  '[Organization] Set organization comments list',
  props<{
    organizationComments?: OrganizationUsersCommentsFunds[];
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// remove organization comment
export const removeOrganizationComment = createAction(
  '[Organization] Remove A Comment',
  props<{ commentId: number; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// add organization comment
export const addOrganizationComment = createAction(
  '[Organization] Add A Comment',
  props<{ comment: string; fundId: number; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const deleteInvoice = createAction(
  '[Organization] Delete Invoice',
  props<{ invoiceId: number; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
