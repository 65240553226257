import { UserFavourites } from '@fund-base/types/users/user-favourites.types';

export interface UserState {
  loading?: boolean;
  userFavouritesList?: UserFavourites[];
}

export const initialUserStateState: UserState = {
  loading: false,
  userFavouritesList: [],
};
