import { Plan } from '@fund-base/types/plan/plan.types';

export interface PlanState {
  plan?: Plan;
  loading: boolean;
  plans?: Plan[];
}

export const initialPlansState: PlanState = {
  plan: undefined,
  loading: false,
  plans: [],
};
