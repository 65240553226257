import { createAction, props } from '@ngrx/store';
import { PhilanthropicContent, Tags } from '@fund-base/types/philanthropic-content/philanthropic-content.types';

export const loadContent = createAction(
  '[Philanthropic Content] Load Content',
  props<{ tags: Tags[]; page: number; pageSize: number; isReplace: boolean }>()
);

export const loadContentSuccess = createAction(
  '[Philanthropic Content] Load Content Success',
  props<{ content: PhilanthropicContent[]; page: number; hasMore: boolean; isReplace: boolean }>()
);

export const loadContentFailure = createAction(
  '[Philanthropic Content] Load Content Failure',
  props<{ error: string }>()
);

export const updateTags = createAction('[Philanthropic Content] Update Tags', props<{ tags: string[] }>());
