import { createAction, props } from '@ngrx/store';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import {
  CreateSubmissionDto,
  CreateTaskDto,
  CreateWorkPlanDto,
  UpdateSubmissionDto,
  UpdateTaskDto,
  UpdateWorkPlanDto,
  WorkPlan,
} from '@fund-base/types/workplan/workplan.types';
import { Params } from '@fund-base/shared/interfaces/filter.interfaces';

// set plan
export const setWorkPlan = createAction('[WorkPlan] Set workplan', props<{ workplan?: WorkPlan }>());

// plan loading
export const setWorkPlanLoading = createAction('[WorkPlan] Set Workplan loading', props<{ loading: boolean }>());

//get plans
export const fetchWorkPlans = createAction(
  '[WorkPlan] Fetch workplans',
  props<{ params: Params; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set plans
export const setWorkPlans = createAction('[WorkPlan] Set workplans', props<{ workplans?: WorkPlan[] }>());

export const createWorkPlan = createAction(
  '[WorkPlan] Create Work Plan',
  props<{ workplan: CreateWorkPlanDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const updateWorkPlan = createAction(
  '[WorkPlan] Update Work Plan',
  props<{ workplan: UpdateWorkPlanDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const createSubmission = createAction(
  '[Submission] create Submission',
  props<{ submission: CreateSubmissionDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const updateSubmission = createAction(
  '[Submission] update Submission',
  props<{ updateSubmission: UpdateSubmissionDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const createTask = createAction(
  '[Task] Create Task',
  props<{ task: CreateTaskDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const updateTask = createAction(
  '[Task] Update Task',
  props<{ task: UpdateTaskDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const deleteTask = createAction(
  '[Task] Delete Task',
  props<{ taskId: number; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
