import { CalendarEvent, CalendarEventType } from '@fund-base/types/calendar/calendar.types';

// fixed mock data
export const mockCalendarEvents: CalendarEvent[] = generateMockCalendarEvents();

// mock generators
export function generateMockCalendarEvents(): CalendarEvent[] {
  return [
    {
      id: Math.floor(Math.random() * 100 + 1),
      type: CalendarEventType.task,
      title: 'Project Funding 1',
      fromDate: new Date(2022, 6, 1),
    },
    {
      id: Math.floor(Math.random() * 100 + 1),
      type: CalendarEventType.task,
      title: 'Project Funding 2',
      fromDate: new Date(2022, 6, 7),
    },
    {
      id: Math.floor(Math.random() * 100 + 1),
      type: CalendarEventType.atlasEvent,
      title: 'Atlas Event',
      fromDate: new Date(2022, 6, 3),
    },
  ];
}
