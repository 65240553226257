import { Observable } from 'rxjs';
import { User } from '@fund-base/types/auth/auth.types';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  authGoogleApiUrl,
  authLoginGoogleApiUrl,
  googleApiTokenInfoUri,
  googleCalendarApiUrl,
  googleCalendarScopes,
  googleClientId,
  googlePlacesApiUrl,
  googleSignInScopes,
} from '@fund-base/constants/google/google.constants';
import { GooglePlace } from '@fund-base/types/google/google-places.types';

declare var google: any;

@Injectable({ providedIn: 'root' })
export class GoogleService {
  constructor(private oAuthService: OAuthService, private httpClient: HttpClient) {}

  // signup google
  authWithGoogle(email: string, accessToken: string): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.post<{ user?: User; accessToken?: string }>(authGoogleApiUrl, {
      email: email,
      token: accessToken,
    });
  }

  // signin google
  loginWithGoogle(email: string, accessToken: string): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.post<{ user?: User; accessToken?: string }>(authLoginGoogleApiUrl, {
      email: email,
      token: accessToken,
    });
  }

  // get token info
  getGoogleToken(): Observable<any> {
    return new Observable(observer => {
      // creating google client
      const client = google.accounts.oauth2.initTokenClient({
        client_id: googleClientId,
        scope: googleSignInScopes + ' https://www.googleapis.com/auth/user.phonenumbers.read',
        callback: '',
      });

      const focusEventHandler = () => {
        observer.error({
          error: 'client_focused_back_to_window',
        });
        window.removeEventListener('focus', focusEventHandler); // removing the event listener to avoid memory leaks
      };
      // adding an event listener to detect if user is back to the webpage
      // if the user "focus" back to window then we shall close the current auth session
      window.addEventListener('focus', focusEventHandler);

      // Settle this promise in the response callback for requestAccessToken()
      client.callback = (resp: any) => {
        if (resp.error) {
          observer.error(resp);
        }
        observer.next(resp);
        observer.complete();
      };
      // requesting access token
      client.requestAccessToken();
    });
  }

  // get token info
  getGoogleTokenCalendar(): Observable<any> {
    return new Observable(observer => {
      // creating google client
      const client = google.accounts.oauth2.initTokenClient({
        client_id: googleClientId,
        scope: googleCalendarScopes,
        callback: '',
      });

      // const focusEventHandler = () => {
      //   observer.error({
      //     error: 'client_focused_back_to_window',
      //   });
      //   window.removeEventListener('focus', focusEventHandler); // removing the event listener to avoid memory leaks
      // };
      // // adding an event listener to detect if user is back to the webpage
      // // if the user "focus" back to window then we shall close the current auth session
      // window.addEventListener('focus', focusEventHandler);

      // Settle this promise in the response callback for requestAccessToken()
      client.callback = (resp: any) => {
        if (resp.error) {
          observer.error(resp);
        }
        observer.next(resp);
        observer.complete();
      };
      // requesting access token
      client.requestAccessToken();
    });
  }

  // get token info
  getGoogleTokenInfo(accessToken?: string): Observable<any> {
    return this.httpClient.get<any>(`${googleApiTokenInfoUri}?access_token=${accessToken}`);
  }

  // google calendar
  googleCalendar(email: string, accessToken: string): Observable<any> {
    return this.httpClient.post<{}>(googleCalendarApiUrl, {
      email: email,
      token: accessToken,
    });
  }

  // google places string search
  searchGooglePlaces(strSearch: string, lang: string): Observable<GooglePlace[]> {
    return this.httpClient.post<GooglePlace[]>(googlePlacesApiUrl, {
      strSearch: strSearch,
      lang: lang,
    });
  }
}
