import { Action, createReducer, on } from '@ngrx/store';
import { fetchPlans, setPlan, setPlanLoading, setPlans } from '@fund-base/store/actions/plan.actions';
import { initialPlansState, PlanState } from '@fund-base/store/state/plan.state';

export const featurePlanKey = 'plan';

const _planReducer = createReducer<PlanState, Action>(
  initialPlansState,
  on(fetchPlans, state => state),
  on(setPlanLoading, (state, { loading }) => ({ ...state, loading })),
  on(setPlan, (state, { plan }) => ({ ...state, plan })),
  on(setPlans, (state, { plans }) => ({ ...state, plans }))
);

export function planReducer(state: PlanState | undefined, action: Action) {
  return _planReducer(state, action);
}
