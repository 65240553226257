import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import posthog from 'posthog-js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  posthog.init('phc_bl7GljRXbLA5a3wZtmoTzUGJNnnBGJP7lm5tgsaLhEq', {
    api_host: 'https://us.i.posthog.com',
  });
} else {
  posthog.init('phc_uWOzhE5Z5GXZ1DUjkqs3RxoijY8YlZ7FWNGFPQdGyuz', {
    api_host: 'https://us.i.posthog.com',
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
