import { createAction, props } from '@ngrx/store';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { FundLanguages } from '@fund-base/types/languages/languages.types';

// loading
export const setLanguagesLoading = createAction('[Languages] Set languages loading', props<{ loading: boolean }>());

// fund languages
export const fetchFundLanguages = createAction(
  '[Languages] Fetch fund languages',
  props<{ onSuccess?: SuccessCallback; onError?: ErrorCallback }>()
);

export const setFundLanguages = createAction(
  '[Languages] Set fund languages',
  props<{ fundLanguages: FundLanguages[] }>()
);
