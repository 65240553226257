import { Injectable } from '@angular/core';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  constructor() {}

  init() {
    posthog.init('phc_bl7GljRXbLA5a3wZtmoTzUGJNnnBGJP7lm5tgsaLhEq', { api_host: 'https://app.posthog.com' });
  }

  identifyUser(userId: string, details: any) {
    posthog.identify(userId, details);
  }

  reset() {
    posthog.reset();
  }
}
