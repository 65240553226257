import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthResetPasswordDto, ForgotPasswordDto, User } from '@fund-base/types/auth/auth.types';
import {
  authAuthenticateApiUrl,
  authAuthenticateVisitorApiUrl,
  authEmailVerificationApiUrl,
  authForgotPasswordApiUrl,
  authIsEmailVerifiedUrl,
  authIsVisitorEmailVerifiedUrl,
  authLoginApiUrl,
  authReSendEmailVerificationApiUrl,
  authResetPasswordApiUrl,
  authSignupApiUrl,
  authSignupMembershipApiUrl,
  authSignupOldFbAccountApiUrl,
  authSignupOrganizationApiUrl,
  authSignupVisitorApiUrl,
  authSignupVisitorPlanApiUrl,
  authSignupVisitorUpdateApiUrl,
  authTransformVisitorToUserUrl,
  authVisitorAiIncreaseLettersApiUrl,
  authVisitorEmailVerificationApiUrl,
  authVisitorFreePlanApiUrl,
  authVisitorStatusApiUrl,
} from '@fund-base/constants/auth/auth.constants';
import { Observable } from 'rxjs';
import { Organization } from '@fund-base/types/organization/organization.types';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  // login
  login(email?: string, password?: string): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.post<{ user?: User; accessToken?: string }>(authLoginApiUrl, {
      email,
      password,
    });
  }

  // signup details
  signUp(user?: User): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.post<{ user?: User; accessToken?: string }>(authSignupApiUrl, user);
  }

  signUpVisitor(user?: User): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.post<{ user?: User; accessToken?: string }>(authSignupVisitorApiUrl, user);
  }

  signUpVisitorPlan(plan?: any): Observable<any> {
    return this.httpClient.put<any>(authSignupVisitorPlanApiUrl, plan);
  }

  signUpVisitorUpdate(user?: User): Observable<any> {
    return this.httpClient.put<any>(authSignupVisitorUpdateApiUrl, user);
  }

  signUpOldFbAccount(
    email: string,
    userName: string,
    oldPassword: string,
    newPassword: string
  ): Observable<{ user?: User }> {
    return this.httpClient.post<{ user?: User }>(authSignupOldFbAccountApiUrl, {
      user: {
        email: email,
        userName: userName,
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
    });
  }

  // signup organization
  signUpOrganization(organization?: Organization): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.put<{ organization?: Organization; accessToken?: string }>(
      authSignupOrganizationApiUrl,
      organization
    );
  }

  // signup email-verification
  signUpEmailVerification(token?: string): Observable<{ user?: User }> {
    return this.httpClient.put<{ user?: User }>(`${authEmailVerificationApiUrl}/${token}`, { token: token });
  }

  signUpVisitorEmailVerification(token?: string): Observable<{ user?: User }> {
    return this.httpClient.put<{ user?: User }>(`${authVisitorEmailVerificationApiUrl}/${token}`, { token: token });
  }

  // signup re-send-email-verification
  signUpReSendEmailVerification(token?: string): Observable<{ accessToken?: string }> {
    return this.httpClient.put<{}>(authReSendEmailVerificationApiUrl, token);
  }

  // signup is-email-verified
  signUpIsEmailVerified(): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.put<{}>(authIsEmailVerifiedUrl, {});
  }

  transformVisitorToUser(): Observable<{ user?: User; accessToken?: string; plan: any }> {
    return this.httpClient.put<{ user?: User; accessToken?: string; plan: any }>(authTransformVisitorToUserUrl, {});
  }

  signUpIsVisitorEmailVerified(): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.put<{}>(authIsVisitorEmailVerifiedUrl, {});
  }

  // signup membership
  signUpMembership(plan_id?: number): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.put<{ plan_id?: number; accessToken?: string }>(authSignupMembershipApiUrl, {
      plan_id: plan_id,
    });
  }

  // authenticate
  authenticate(): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.get<{ user?: User; accessToken?: string }>(authAuthenticateApiUrl);
  }

  authenticateVisitor(): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.get<{ user?: User; accessToken?: string }>(authAuthenticateVisitorApiUrl);
  }

  // forgot password
  forgotPassword(forgotPasswordDto: ForgotPasswordDto): Observable<boolean> {
    return this.httpClient.post<boolean>(authForgotPasswordApiUrl, forgotPasswordDto);
  }

  // reset password with token
  resetPassword(resetPasswordDto: AuthResetPasswordDto): Observable<boolean> {
    return this.httpClient.post<boolean>(authResetPasswordApiUrl, resetPasswordDto);
  }

  fetchNumbersFreePlanVisitors(): Observable<any> {
    return this.httpClient.get<any>(authVisitorFreePlanApiUrl);
  }

  updateNumberAiLettersVisitors(): Observable<any> {
    return this.httpClient.get<any>(authVisitorAiIncreaseLettersApiUrl);
  }

  updateVisitorStatus(status: string): Observable<any> {
    return this.httpClient.put<any>(authVisitorStatusApiUrl, { status });
  }
}
