export enum Language {
  English = 'en',
  Hebrew = 'he',
  Spanish = 'es',
}

export enum Direction {
  LTR = 'ltr',
  RTL = 'rtl',
}
