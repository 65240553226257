import { Action, createReducer, on } from '@ngrx/store';
import { initialPaymentsState, PaymentsState } from '@fund-base/store/state/payments.state';
import { fetchInvoices, setInvoices, setPaymentLoading } from '@fund-base/store/actions/payments.actions';

export const featurePaymentsKey = 'payments';

const _paymentsReducer = createReducer<PaymentsState, Action>(
  initialPaymentsState,
  on(fetchInvoices, state => state),
  on(setPaymentLoading, (state, { loading }) => ({ ...state, loading })),
  on(setInvoices, (state, { invoices }) => ({ ...state, invoices }))
);

export function paymentsReducer(state: PaymentsState | undefined, action: Action) {
  return _paymentsReducer(state, action);
}
