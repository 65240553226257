import { Interest } from '@fund-base/types/types/types.types';
import { User } from '@fund-base/types/auth/auth.types';
import { WorkPlan } from '@fund-base/types/workplan/workplan.types';
import { Membership } from '@fund-base/types/membership/membership.types';
import { GeoLocation } from '@fund-base/types/location/location.types';
import { UserRoleEnum } from '@fund-base/shared/enums/user-role.enum';

// organization
export interface Organization {
  id?: number;
  organization_name?: string;
  owner_name?: string;
  owner_email?: string;
  country?: string;
  initialized?: boolean;
  membership?: Membership[];
  users?: User[];
  address?: string;
  phone_number?: string;
  work_plans?: WorkPlan[];
  on_boarding_completed?: boolean;
  status?: number;
}

// organization settings
export interface OrganizationSettings {
  interests?: Interest[];
  locations?: GeoLocation[];
  range?: string;
}

// organization onboarding step
export enum OrganizationOnboardingStep {
  areaOfInterests,
  areaOfActivities,
  fundingRange,
  completed,
}

// add new user
export interface UserRolePair {
  userEmail?: string;
  userRole?: UserRoleEnum;
}

// add new user
export interface CreatNewUser {
  firstName?: string;
  lastName?: string;
  password?: string;
  email?: string;
  role?: UserRoleEnum;
}

export interface InvoiceTableElement {
  id?: string;
  amount?: number;
  date?: Date;
  link?: string;
}
